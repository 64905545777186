/* // _general.scss */

/*********************************/
/*             General            */
/*===============================*/
p {
    @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @apply leading-normal;
}

::selection {
    @apply bg-guava-red-600/90 text-white;
}
