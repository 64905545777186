@charset "UTF-8";
/* Template Name: Techwind - Multipurpose Tailwind CSS Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.2.0
   Created: May 2022
   File: Main Css File
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* // _general.scss */
/*********************************/
/*             General            */
/*===============================*/
p {
  @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply leading-normal;
}

::selection {
  @apply bg-guava-red-600/90 text-white;
}

/********************/
/*     Buttons      */
/*==================*/
/* Note: If you want to add your own colors, you can do so by going to this tailwind.config.js file  */
.btn {
  @apply py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center;
  /*  Button icons */
  /* Button Link */
}
.btn.btn-lg {
  @apply py-2.5 px-6 text-lg;
}
.btn.btn-sm {
  @apply py-[5px] px-4 text-sm;
}
.btn.btn-icon {
  @apply p-0 h-9 w-9 inline-flex items-center text-center justify-center text-base;
}
.btn.btn-icon.btn-lg {
  @apply h-12 w-12;
}
.btn.btn-icon.btn-sm {
  @apply h-8 w-8;
}
.btn.btn-link {
  @apply relative p-0 border-none after:content-[""] after:absolute after:h-px after:w-0 after:right-0 after:bottom-0 after:left-0 after:transition-all after:duration-500;
}
.btn.btn-link:hover {
  @apply after:w-full after:right-auto;
}

/********************/
/*    Breadcrumb    */
/*==================*/
.breadcrumb .breadcrumb-item {
  @apply before:content-[""] after:text-sm after:text-black after:dark:text-white after:pl-[5px] last:after:hidden;
}
.breadcrumb .breadcrumb-item:after {
  content: "󰅂" !important;
  font-family: "Material Design Icons";
}
.breadcrumb.breadcrumb-light .breadcrumb-item {
  @apply after:text-white/50;
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  @apply fixed right-0 left-0 top-0 z-999 bg-transparent border-0 transition-all duration-500 ease-in-out;
}
#topnav .logo {
  @apply float-left text-black dark:text-white !important;
}
#topnav .logo .l-dark {
  @apply hidden;
}
#topnav .logo .l-light {
  @apply inline-block;
}
#topnav .logo:focus {
  @apply outline-none;
}
#topnav .logo img {
  height: calc(1.2em + 0.5vw);
  max-height: 2em;
}
#topnav .has-submenu {
  @apply relative;
}
#topnav .has-submenu.active a {
  @apply text-white dark:text-slate-900;
}
#topnav .has-submenu.active .submenu li.active > a {
  @apply text-guava-red-600 dark:text-guava-red-600 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  @apply border-guava-red-600 dark:border-guava-red-600;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border-width: 0px 0.125rem 0.125rem 0px;
  @apply absolute right-[1.25rem] top-[0.8125rem] border-black dark:border-white rounded-[0.5px] inline-block p-[0.1875rem] -rotate-[45deg];
}
#topnav .has-submenu .submenu .has-submenu:hover {
  /* .submenu-arrow {
    @apply border-guava-red-600 dark:border-guava-red-600;
  } */
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-guava-red-600 dark:border-guava-red-600;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  @apply border-guava-red-600 dark:border-guava-red-600;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-guava-red-600 dark:border-guava-red-600;
}
#topnav .navbar-toggle {
  @apply border-0 relative p-0 m-0 cursor-pointer;
}
#topnav .navbar-toggle .lines {
  @apply w-[25px] block relative h-[18px] mt-[30px] mr-0 mb-[26px] ml-[10px];
}
#topnav .navbar-toggle span {
  transition: transform 0.5s ease;
  @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
}
#topnav .navbar-toggle span:last-child {
  @apply mb-0;
}
#topnav .navbar-toggle.open span {
  @apply absolute;
}
#topnav .navbar-toggle.open span:first-child {
  @apply top-[6px] rotate-[45deg];
}
#topnav .navbar-toggle.open span:nth-child(2) {
  @apply invisible;
}
#topnav .navbar-toggle.open span:last-child {
  @apply w-full top-[6px] -rotate-[45deg];
}
#topnav .navbar-toggle.open span:hover {
  @apply bg-guava-red-600;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  @apply bg-transparent;
}
#topnav .buy-button {
  @apply float-right leading-[74px];
}
#topnav .buy-button > li {
  @apply leading-[initial];
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  @apply hidden;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  @apply inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  @apply relative;
}
#topnav .buy-button .search-bar .searchform {
  @apply after:absolute after:right-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none;
}
#topnav .buy-button .search-bar .searchform input[type=text] {
  @apply shadow-none py-[10px] pr-[42px] pl-3 h-11 text-sm block outline-none  !important;
}
#topnav .buy-button .search-bar .searchform input[type=submit] {
  @apply hidden;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "󰍉";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle,
#topnav .buy-menu-btn .dropdown .dropdown-toggle {
  @apply after:hidden;
}
#topnav .navigation-menu {
  @apply list-none m-0 p-0;
}
#topnav .navigation-menu > li {
  @apply float-left block relative my-0 mx-[10px];
}
#topnav .navigation-menu > li > a {
  @apply block text-black dark:text-white text-[13px] bg-transparent font-bold tracking-[1px] leading-6 uppercase px-[15px] !important;
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  @apply text-guava-red;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  @apply text-guava-red dark:text-guava-red !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-bold text-slate-400 !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border-width: 0 2px 2px 0;
  @apply border-black dark:border-white rounded-[0.5px] inline-block p-[3px] rotate-[45deg] absolute transition-all duration-500 -right-px top-[30px];
}
#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  @apply rotate-[225deg];
}
#topnav .menu-extras {
  @apply float-right;
}
#topnav.scroll {
  @apply bg-white dark:bg-slate-900 border-none shadow;
}
#topnav.scroll .navigation-menu > li > a {
  @apply text-black dark:text-white;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  @apply text-guava-red-600;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  @apply border-guava-red-600 dark:border-guava-red-600;
}
#topnav.defaultscroll.dark-menubar .logo {
  @apply leading-[70px];
}
#topnav.defaultscroll.scroll .logo {
  @apply leading-[62px];
}
#topnav.nav-sticky {
  @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-800;
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  @apply text-black dark:text-white !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-guava-red-600 dark:text-guava-red-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  @apply border-guava-red-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-guava-red-600 dark:text-guava-red-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.nav-sticky.tagline-height {
  @apply top-0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  @apply inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  @apply hidden;
}
#topnav.nav-sticky .logo .l-dark {
  @apply inline-block;
}
#topnav.nav-sticky .logo .l-light {
  @apply hidden;
}

.logo {
  @apply font-bold text-[24px] mr-[15px] pt-0 px-[6px] pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[1116px] !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[936px] !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    @apply flex flex-wrap justify-center;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    @apply top-[33px] !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    @apply top-[30px];
  }
  #topnav .navigation-menu > li .submenu {
    transition: all 0.2s ease;
    @apply absolute top-full left-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800;
  }
  #topnav .navigation-menu > li .submenu li {
    @apply relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[11px] uppercase tracking-[0.04em] font-bold text-black dark:text-white leading-[17px];
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    @apply text-guava-red-600 dark:text-guava-red-600 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    @apply list-none pl-0 m-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply whitespace-nowrap left-1/2 -translate-x-1/2 fixed top-auto flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    @apply overflow-hidden align-top w-1/5;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    @apply left-full top-0 ml-[10px] -mt-px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    @apply left-[101%] top-0 ml-[10px] -mt-px;
  }
  #topnav .navigation-menu > li > a {
    @apply py-[25px] min-h-[62px];
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    @apply border-guava-red-600 dark:border-guava-red-600;
  }
  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    @apply text-guava-red-600 dark:text-guava-red-600 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    @apply left-auto right-0 before:left-auto before:right-[10px];
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    @apply left-auto right-full ml-0 mr-[10px];
  }
  #topnav .navigation-menu.nav-light > li > a {
    @apply text-white/50 !important;
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    @apply border-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    @apply border-white/50;
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    @apply border-white !important;
  }
  #topnav .buy-button {
    @apply pl-[15px] ml-[15px];
  }
  #topnav .navbar-toggle {
    @apply hidden;
  }
  #topnav #navigation {
    @apply block !important;
  }
  #topnav.scroll {
    @apply top-0;
  }
  #topnav.scroll .navigation-menu > li > a {
    @apply py-5;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    @apply py-[25px];
  }
}
@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 min-h-[74px];
  }
  #topnav .logo .l-dark {
    @apply inline-block !important;
  }
  #topnav .logo .l-light {
    @apply hidden !important;
  }
  #topnav .container {
    @apply w-auto;
  }
  #topnav #navigation {
    @apply max-h-[400px] shadow;
  }
  #topnav .navigation-menu {
    @apply float-none;
  }
  #topnav .navigation-menu > li {
    @apply float-none;
  }
  #topnav .navigation-menu > li .submenu {
    @apply hidden list-none pl-5 m-0;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block relative py-[7px] px-[15px] uppercase text-[11px] tracking-[0.04em] font-bold text-black dark:text-white !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    @apply py-[7px] px-[15px];
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    @apply list-none pl-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
  }
  #topnav .navigation-menu > li .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    @apply hidden list-none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li > a {
    @apply text-black dark:text-white py-[10px] px-[20px] after:absolute after:right-[15px];
  }
  #topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover, #topnav .navigation-menu > li.has-submenu.open > a {
    @apply text-guava-red-600;
  }
  #topnav .menu-extras .menu-item {
    @apply border-gray-200 dark:border-gray-700;
  }
  #topnav .navbar-header {
    @apply float-left;
  }
  #topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
    @apply inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
    @apply hidden;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    @apply rotate-[45deg] absolute right-5 top-3;
  }
  #topnav .has-submenu.active a {
    @apply text-guava-red-600;
  }

  #navigation {
    @apply absolute top-[74px] left-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
  }
  #navigation.open {
    @apply block overflow-y-auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    @apply right-2 top-4;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 mt-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 ml-0 mr-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    @apply visible opacity-100 ml-0 mr-0;
  }

  .navbar-toggle {
    @apply block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    @apply block my-0 mx-[10px] py-[10px] px-5 !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}

@media screen and (max-width: 575px) {
  .tagline {
    @apply hidden;
  }

  .tagline-height {
    @apply top-0 !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] !important;
  }
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  @apply text-guava-red-600/10;
}
.sidebar-nav li.active a {
  @apply text-guava-red-600;
}
.sidebar-nav li.account-menu.active .navbar-link, .sidebar-nav li.account-menu:hover .navbar-link {
  @apply text-guava-red-600 dark:text-white !important;
}

/* // _footer.scss */
/*********************************/
/*         Footer                */
/*===============================*/
.footer .foot-subscribe input::placeholder {
  @apply text-gray-200;
}

/*********************************/
/*             Helper            */
/*===============================*/
.container {
  @apply relative;
}

/* Cookies */
.cookie-popup-not-accepted {
  @apply block;
  animation: cookie-popup-in 0.5s ease forwards;
}

.cookie-popup-accepted {
  @apply hidden;
}

@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}
/* Shapes */
.shape {
  @apply absolute right-0 -bottom-px left-0;
}
.shape > svg {
  @apply w-full h-auto;
  transform: scale(2);
  transform-origin: theme("transformOrigin.top") theme("transformOrigin.center");
}
.shape.marketing-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  @apply h-[250px] bg-white dark:bg-slate-900;
}

@media (max-width: 768px) {
  .shape.marketing-hero {
    @apply h-[140px];
  }
}
@media (max-width: 425px) {
  .shape {
    @apply -bottom-[2px];
  }
  .shape.marketing-hero {
    @apply h-[60px];
  }
}
/* Feature post placeholder */
.feature-posts-placeholder {
  @apply absolute bottom-0 left-0 right-0 h-2/3;
}

@media (max-width: 425px) {
  .feature-posts-placeholder {
    @apply h-[80%];
  }
}
.features-absolute {
  @apply relative z-2 transition-all duration-500 ease-in-out -mt-[200px] m-0;
}
.features-absolute.blog-search {
  @apply -mt-[120px] m-0;
}

@media (max-width: 768px) {
  .features-absolute {
    @apply -mt-[140px] m-0;
  }
  .features-absolute.blog-search {
    @apply -mt-[90px] m-0;
  }
}
/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  @apply fixed inset-0;
}
#preloader #status {
  @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
}
#preloader #status .spinner {
  @apply w-10 h-10 relative my-[100px] mx-auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  @apply w-full h-full rounded-full bg-indigo-600/60 absolute top-0 left-0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/* Switcher */
.label .ball {
  transition: transform 0.2s linear;
  @apply translate-x-0;
}

.checkbox:checked + .label .ball {
  @apply translate-x-6;
}

.mover {
  animation: mover 1.5s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
/*********************************/
/*             Home            */
/*===============================*/
/* background-effect For Job Hero */
.background-effect .circles {
  @apply absolute inset-0 h-full w-full overflow-hidden;
}
.background-effect .circles li {
  @apply absolute block -bottom-[150px] bg-indigo-600/30;
  animation: animate 25s linear infinite;
}
.background-effect .circles li.brand-img:nth-child(1), .background-effect .circles li.brand-img:nth-child(2), .background-effect .circles li.brand-img:nth-child(3), .background-effect .circles li.brand-img:nth-child(4), .background-effect .circles li.brand-img:nth-child(5), .background-effect .circles li.brand-img:nth-child(6), .background-effect .circles li.brand-img:nth-child(7), .background-effect .circles li.brand-img:nth-child(8), .background-effect .circles li.brand-img:nth-child(9), .background-effect .circles li.brand-img:nth-child(10) {
  @apply bg-transparent;
}
.background-effect .circles li:nth-child(1), .background-effect .circles li:nth-child(2), .background-effect .circles li:nth-child(3), .background-effect .circles li:nth-child(4), .background-effect .circles li:nth-child(5), .background-effect .circles li:nth-child(6), .background-effect .circles li:nth-child(7), .background-effect .circles li:nth-child(8), .background-effect .circles li:nth-child(9), .background-effect .circles li:nth-child(10) {
  @apply w-12 h-12;
}
.background-effect .circles li:nth-child(1) {
  @apply left-1/4;
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(2) {
  @apply left-[10%];
  animation-delay: 2s;
  animation-duration: 12s;
}
.background-effect .circles li:nth-child(3) {
  @apply left-[70%];
  animation-delay: 4s;
}
.background-effect .circles li:nth-child(4) {
  @apply left-[40%];
  animation-delay: 0s;
  animation-duration: 18s;
}
.background-effect .circles li:nth-child(5) {
  @apply left-[65%];
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(6) {
  @apply left-3/4;
  animation-delay: 3s;
}
.background-effect .circles li:nth-child(7) {
  @apply left-[35%];
  animation-delay: 7s;
}
.background-effect .circles li:nth-child(8) {
  @apply left-1/2;
  animation-delay: 15s;
  animation-duration: 45s;
}
.background-effect .circles li:nth-child(9) {
  @apply left-[20%];
  animation-delay: 2s;
  animation-duration: 35s;
}
.background-effect .circles li:nth-child(10) {
  @apply left-[85%];
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 10px;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
/* studio home */
.studio-wrapper {
  @apply before:content-[""] before:absolute before:bottom-[14rem] before:left-[50rem] before:w-[60rem] before:h-[30rem] before:rounded-[18rem] before:rotate-[135deg] before:bg-indigo-600/5 dark:before:bg-indigo-600/10;
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .studio-wrapper {
    @apply before:bottom-[10rem] before:left-[30rem] before:w-[40rem] before:h-[20rem];
  }
}
@media (max-width: 768px) {
  .studio-wrapper {
    @apply before:left-[12rem];
  }
}
@media (max-width: 767px) {
  .studio-wrapper {
    @apply before:left-0;
  }
}
/* Startup Hero */
.startup-wrapper {
  @apply before:content-[""] before:absolute before:bottom-40 before:left-[45rem] before:w-[90rem] before:h-[35rem] before:rotate-[115deg] before:bg-indigo-600;
  @apply after:content-[""] after:absolute after:bottom-96 after:left-[30rem] after:w-[75rem] after:h-40 after:rotate-[115deg] after:bg-indigo-600/10 after:-z-1;
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .startup-wrapper {
    @apply before:left-40 before:w-[75rem] before:h-[30rem] after:left-0 after:bottom-80 after:w-[75rem] after:h-60;
  }
}
@media (max-width: 768px) {
  .startup-wrapper {
    @apply before:left-40 after:left-0;
  }
}
@media (max-width: 767px) {
  .startup-wrapper {
    @apply before:left-0 after:-left-40;
  }
}
/* Kenburn Effect for Business Demo */
.image-wrap {
  animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
  0% {
    transform-origin: bottom;
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
@keyframes ppb_kenburns {
  0% {
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.3) translate(-10%, 10%);
  }
}
/* Saas */
.home-wrapper {
  @apply before:content-[""] before:absolute before:-top-[30%] before:-left-80 before:right-0 before:w-[140rem] before:h-[65rem] before:-rotate-12 before:bg-guava-gray/5 dark:before:bg-indigo-600/10 before:z-1;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .home-wrapper {
    @apply before:-top-[50%];
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .home-wrapper {
    @apply before:-top-[80%];
  }
}
@media (max-width: 425px) {
  .home-wrapper {
    @apply before:-top-[90%];
  }
}
/*********************************/
/*             Contact           */
/*===============================*/
.form-input {
  @apply w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent;
}
.form-input:focus {
  @apply border-indigo-600 shadow-none;
}

/* Validation */
.error {
  @apply my-2 mx-0 hidden text-red-600;
}

#ajaxsuccess {
  @apply text-base w-full hidden clear-both my-2 mx-0;
}

.error_message,
#success_page {
  @apply p-2.5 mb-5 text-center rounded-md;
}

.error_message {
  @apply bg-red-600/5 text-red-600;
}

.contact-loader {
  @apply hidden;
}

#success_page {
  @apply bg-emerald-600/5 text-emerald-600;
}

/*****************/
/*  Swiper Slider     */
/*****************/
.swiper-slider-hero .swiper-container,
.swiper-slider-hero .swiper-slide .slide-inner {
  @apply absolute right-0 top-0 w-full h-full;
}
.swiper-slider-hero .swiper-container .swiper-button-prev,
.swiper-slider-hero .swiper-container .swiper-button-next {
  @apply bg-transparent w-[35px] h-[35px] leading-[35px] -mt-[30px] bg-none border border-solid border-white/50;
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before,
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  @apply font-bold text-white;
  font-family: "Material Design Icons";
}
.swiper-slider-hero .swiper-container .swiper-button-prev:hover,
.swiper-slider-hero .swiper-container .swiper-button-next:hover {
  @apply bg-indigo-600 border-indigo-600;
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "󰅁";
}
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "󰅂";
}
.swiper-slider-hero .swiper-pagination-bullet {
  @apply text-gray-50 bg-transparent;
}
.swiper-slider-hero .swiper-pagination-bullet-active {
  @apply text-white;
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
  @apply bottom-[45px];
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply my-0 mx-[13px];
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.client-testi {
  @apply cursor-e-resize;
}
.client-testi .content {
  @apply before:content-[""] before:absolute before:top-8 before:left-0 before:ml-3 before:box-border before:border-8 before:border-solid before:origin-[0] before:rotate-[135deg] before:shadow before:border-t-transparent before:border-r-white dark:before:border-r-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-l-transparent;
  /* border-color: transparent theme('colors.white') theme('colors.white') transparent; */
}

.customer-testi {
  @apply cursor-e-resize;
}
.customer-testi .content {
  @apply before:content-[""] before:absolute before:left-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-r-white dark:before:border-r-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-l-transparent before:shadow-testi dark:before:shadow-gray-700;
}
.customer-testi .content:before {
  transform-origin: 0 0;
}

.tns-nav {
  @apply text-center mt-3;
}
.tns-nav button {
  @apply rounded-[3px] bg-guava-green-600/30 transition-all duration-500 ease-in-out border-0 m-1 p-[5px];
}
.tns-nav button.tns-nav-active {
  @apply bg-guava-green-600 rotate-[45deg];
}

/* Tns control */
.tns-controls button[data-controls=prev],
.tns-controls button[data-controls=next] {
  @apply absolute top-2/4 -translate-y-2/4 text-base w-8 h-8 rounded-full bg-white dark:bg-slate-900 text-dark dark:text-white border-0 transition-all duration-500 ease-in-out z-10 shadow-md dark:shadow-gray-800;
}
.tns-controls button[data-controls=prev]:hover,
.tns-controls button[data-controls=next]:hover {
  @apply bg-guava-green-600 text-white;
}
.tns-controls button[data-controls=prev] {
  @apply left-0;
}
.tns-controls button[data-controls=next] {
  @apply right-0;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-360px * 6));
  }
}
.slider:after {
  transform: rotateZ(360deg);
}
.slider .slide-track {
  animation: scroll 120s linear infinite;
  width: calc(360px * 20);
}

#Password {
  display: none;
}